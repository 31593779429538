import dayjs from 'dayjs';
import { type FC } from 'react';
import styled from 'styled-components';

import { media } from 'src/styles';

type Props = {
  className?: string;
};

export const Footer: FC<Props> = ({ className }) => (
  <Wrapper className={className ?? ''}>
    &copy;{dayjs().format('YYYY')} co&co.Ltd. All Rights Reserved.
    <br />
    Photographs by yurika kono
  </Wrapper>
);

const Wrapper = styled.footer`
  font-size: ${({ theme }) => theme.font.size.small};
  ${media.lessThanIpadVertical`
    text-align: center;
  `};
`;

export default Footer;
