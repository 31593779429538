import { graphql } from 'gatsby';
import { type FC } from 'react';
import styled from 'styled-components';

import { Img } from 'src/components/common/atoms';
import { media } from 'src/styles';

export const fragment = graphql`
  fragment ImagesItem on ContentfulAsset {
    id
    title
    gatsbyImageData(
      sizes: "(min-width: 1200px) 860px, (min-width: 768px) calc((100vw - 69px) * 0.75), 100vw"
      quality: 90
    )
  }
`;

type Props = {
  className?: string;
  images: readonly Queries.Maybe<Queries.ImagesItemFragment>[];
};

export const Images: FC<Props> = ({ className, images }) => (
  <Wrapper className={className ?? ''}>
    {images.map((image, index) => (
      <Item key={image?.id ?? `images-list-item-${index}`}>
        <Img file={image} alt={image?.title ?? ''} />
      </Item>
    ))}
  </Wrapper>
);

const Item = styled.li`
  & + & {
    margin-top: 15px;
  }
  .vertical-image {
    margin-left: auto;
    margin-right: auto;
  }
  ${media.lessThanIpadVertical`
    .horizontal-image {
      margin-left: -${({ theme }) => theme.structure.margin.vertical.sp}px;
      margin-right: -${({ theme }) => theme.structure.margin.vertical.sp}px;
    }
    .vertical-image {
      width: 70%;
    }
  `}
  ${media.ipadVerticalOrMore`
    & + & {
      margin-top: 23px;
    }
    .vertical-image {
      width: calc((100% / 6) * 4);
    }
  `}
`;

const Wrapper = styled.ul`
  list-style: none;
  padding: 0;
`;

export default Images;
