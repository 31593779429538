import { type FC } from 'react';
import styled from 'styled-components';

import { MenuListItem, Props as MenuListItemProps } from './menu-list-item';

type Props = {
  className?: string;
  menus: MenuListItemProps[];
  isSubMenu?: boolean;
};

export const MenuList: FC<Props> = ({
  className,
  menus,
  isSubMenu = false,
}) => (
  <Wrapper className={className || ''}>
    {menus.map((menu, index) => (
      <StyledMenuListItem
        key={`header-menu-${index}`}
        isSubMenu={isSubMenu}
        {...menu}
      />
    ))}
  </Wrapper>
);

const StyledMenuListItem = styled(MenuListItem)`
  line-height: 1;
  & + & {
    margin-top: ${({ theme }) => theme.structure.margin.block.small}px;
  }
`;

const Wrapper = styled.ul`
  list-style: none;
  padding: 0;
`;

export default MenuList;
