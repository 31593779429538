import { GatsbyLinkProps, Link } from 'gatsby';
import { type FC } from 'react';
import styled, { css } from 'styled-components';

import { ScrollLink } from './scroll-link';

type Props = GatsbyLinkProps<unknown> & {
  className?: string;
};

export const Anchor: FC<Props> = ({
  className,
  to,
  children,
  ['aria-label']: ariaLabel,
  activeClassName = 'current',
  partiallyActive = false,
}) => {
  const isInternal = to?.startsWith('/');
  const isHash = to?.startsWith('#');
  if (isInternal) {
    return (
      <StyledLink
        className={className ?? ''}
        to={to?.endsWith('/') ? to : `${to}/`}
        aria-label={ariaLabel ?? ''}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
      >
        {children}
      </StyledLink>
    );
  } else if (isHash) {
    return (
      <StyledScrollLink
        className={className ?? ''}
        to={to.slice(1)}
        spy={true}
        smooth={'easeOutQuint'}
        duration={600}
        activeClass={'current'}
      >
        {children}
      </StyledScrollLink>
    );
  } else {
    return (
      <StyledA
        className={className ?? ''}
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={ariaLabel ?? ''}
      >
        {children}
      </StyledA>
    );
  }
};

const BaseStyle = css`
  &:focus {
    outline: none;
  }
  &:focus {
    text-decoration: none;
  }

  @media (hover: hover) {
    &:hover {
      text-decoration: none;
    }
  }
`;

const StyledLink = styled(Link)`
  ${BaseStyle}
`;

const StyledScrollLink = styled(ScrollLink)`
  ${BaseStyle};
  cursor: pointer;
`;

const StyledA = styled.a`
  ${BaseStyle}
`;

export default Anchor;
