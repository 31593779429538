import { useMemo, type FC, type ReactNode } from 'react';
import styled from 'styled-components';

import {
  PageHeader,
  Props as PageHeaderProps,
} from 'src/components/common/page-header';
import { Pagination } from 'src/components/common/pagination';
import { media } from 'src/styles';
import { getLangPath, useSlug } from 'src/utils';

type Props = {
  className?: string;
  children?: ReactNode;
  minusMarginTop?: 'large' | 'medium' | 'small' | 'news';
  header: ReactNode | PageHeaderProps;
  pagination?: {
    indexPath?: string | null;
    previousSlug?: string | null;
    nextSlug?: string | null;
  };
};

const usePaginationProps = (pagination: Props['pagination']) => {
  const { lang } = useSlug();
  return useMemo(() => {
    if (!pagination) {
      return null;
    }
    const { previousSlug, nextSlug } = pagination;
    const indexPath = pagination?.indexPath ?? '';
    // NOTE:
    // previousSlugとnextSlugには空白の文字列が入る場合がある
    // （例えば、prevSlugが/news/のindexと同じ場合など）
    // ので、nullとundefinedのみ外れるようにする
    return {
      previousPath:
        previousSlug !== null && previousSlug !== undefined
          ? getLangPath({ path: `${indexPath}${previousSlug}`, lang })
          : undefined,
      indexPath: indexPath
        ? getLangPath({ path: `${indexPath}`, lang })
        : undefined,
      nextPath:
        nextSlug !== null && nextSlug !== undefined
          ? getLangPath({ path: `${indexPath}${nextSlug}`, lang })
          : undefined,
    };
  }, [lang, pagination]);
};

const isPageHeaderProps = (arg: any): arg is PageHeaderProps => 'title' in arg;

export const PageTemplate: FC<Props> = ({
  className,
  children,
  minusMarginTop,
  header,
  pagination,
}) => {
  const paginationProps = usePaginationProps(pagination);
  return (
    <Wrapper
      className={`${className ?? ''} ${
        minusMarginTop ? `minus-margin-top-${minusMarginTop}` : ''
      }`}
    >
      {isPageHeaderProps(header) && <PageHeader {...header} />}
      {!isPageHeaderProps(header) && header}
      <Content>{children}</Content>
      {pagination && <StyledPagination {...paginationProps} />}
    </Wrapper>
  );
};

const Content = styled.div`
  ${media.lessThanIpadVertical`
    margin-top: ${({ theme }) => theme.structure.margin.block.large}px;
  `}
`;

const StyledPagination = styled(Pagination)`
  margin-top: ${({ theme }) => theme.structure.margin.block.large}px;
`;

const Wrapper = styled.div`
  ${media.ipadVerticalOrMore`
    /* NOTE:
      https://qiita.com/fujihiko/items/12ae89e99a69cd979842#:~:text=1.%E3%83%86%E3%82%AD%E3%82%B9%E3%83%88%E8%A6%81%E7%B4%A0%E3%81%AE%E5%85%A8%E9%AB%98,%E3%82%82%E3%81%AE%E3%81%AB%E7%AD%89%E3%81%97%E3%81%8F%E3%81%AA%E3%82%8A%E3%81%BE%E3%81%99%E3%80%82&text=%E5%85%88%E9%A0%AD%E8%A1%8C%E3%81%A0%E3%81%91%E3%82%92%E4%B8%8A,%E5%8F%96%E3%82%8A%E9%99%A4%E3%81%91%E3%81%B0%E5%AE%9F%E7%8F%BE%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%99%E3%80%82
      line-heightの上だけをマイナスすることで、
      gridの始まりを合わせる
     */
    &.minus-margin-top-large {
        margin-top:  calc((1 - ${({ theme }) =>
          theme.lineHeight.heading.ja}) * ${1.8 / 2}rem);
    }
    &.minus-margin-top-medium {
      margin-top:  calc((1 - ${({ theme }) => theme.lineHeight.heading.ja}) * ${
        1.6 / 2
      }rem);
    }
    &.minus-margin-top-small {
      margin-top:  calc((1 - ${({ theme }) => theme.lineHeight.main.ja}) * ${
        1.4 / 2
      }rem);
    }
    &.minus-margin-top-news {
      margin-top: -18px
    }
  `}
`;

export default PageTemplate;
