import { type FC, type ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  className?: string;
  children?: ReactNode;
};

export const EntryTitleSingle: FC<Props> = ({ className, children }) => (
  <Title className={className ?? ''}>{children}</Title>
);

const Title = styled.h1`
  font-size: ${({ theme }) => theme.font.size.xLarge};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: ${({ theme }) => theme.lineHeight.heading.ja};
`;

export default EntryTitleSingle;
