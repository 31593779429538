import { type FC, type ReactNode } from 'react';
import styled from 'styled-components';

import { media } from 'src/styles';

type Props = {
  className?: string;
  left?: ReactNode;
  right?: ReactNode;
};

export const PageGrid: FC<Props> = ({ className, left, right }) => (
  <Wrapper className={className ?? ''}>
    {left && <Left>{left}</Left>}
    {right && <Right>{right}</Right>}
  </Wrapper>
);

const Left = styled.div`
  ${media.ipadVerticalOrMore`
    grid-row: 1 / 1;
    grid-column: 1 / 3;
  `}
`;

const Right = styled.div`
  ${media.ipadVerticalOrMore`
    grid-row: 1 / 1;
    grid-column: 3 / 9;
  `}
`;

const Wrapper = styled.div`
  ${media.ipadVerticalOrMore`
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: ${({ theme }) => theme.structure.margin.vertical.pc}px;
  `}
`;

export default PageGrid;
