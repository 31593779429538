import { type FC } from 'react';
import styled from 'styled-components';

import { Anchor } from 'src/components/common/atoms';
import type { MenuProps } from 'src/constants';
import { BorderBottomStyle, media } from 'src/styles';
import { useIsMobile } from 'src/utils';

export type Props = MenuProps & {
  className?: string;
  isSubMenu?: boolean;
};

export const MenuListItem: FC<Props> = ({
  className,
  name,
  label,
  href,
  children,
  isSubMenu,
}) => {
  const isMobile = useIsMobile();
  const hasChildMenus = !!(!isMobile && children);
  return (
    <Wrapper className={className || ''}>
      <StyledAnchor
        className={`${isSubMenu ? 'border-type-thin' : ''}`}
        to={href}
        activeClassName={'current'}
        partiallyActive={true}
      >
        {label}
      </StyledAnchor>
      {hasChildMenus && (
        <ChildMenuList>
          {children?.map((child, index) => (
            <ChildMenuListItem key={child?.id ?? `sub-menu-list-item-${index}`}>
              <StyledChildAnchor
                className="child-anchor"
                to={child.href}
                activeClassName={'current'}
                partiallyActive={true}
              >
                {child.label}
              </StyledChildAnchor>
            </ChildMenuListItem>
          ))}
        </ChildMenuList>
      )}
    </Wrapper>
  );
};

const StyledAnchor = styled(Anchor)`
  text-decoration: none;
  position: relative;
  &.current,
  &:focus-visible {
    ${BorderBottomStyle}
  }
  &:focus {
    outline: none;
  }

  /*
    NOTE:
    mobileの際の、クリックした後にhoverのスタイルが残る問題に対応
    https://css-tricks.com/solving-sticky-hover-states-with-media-hover-hover/
    */
  @media (hover: hover) {
    &:hover {
      ${BorderBottomStyle}
    }
  }
`;

const StyledChildAnchor = styled(Anchor)`
  text-decoration: none;
  position: relative;
  &.current,
  &:focus-visible {
    text-decoration: underline;
  }
  &:focus {
    outline: none;
  }
  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
`;

const ChildMenuListItem = styled.li`
  ${media.ipadVerticalOrMore`
    & + & {
      margin-top: 16px;
    }
  `}
`;

const ChildMenuList = styled.ul`
  display: none;
  ${media.ipadVerticalOrMore`
    ${StyledAnchor}.current + & {
      display: block;
      list-style: none;
      padding: 0;
      padding-left: 1em;
      font-weight: normal;
      margin-top: 18px;
    }
  `}
`;

const Wrapper = styled.li`
  letter-spacing: ${({ theme }) => theme.letterSpacing.menu};
`;

export default MenuListItem;
