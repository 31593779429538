import { type FC, type ReactNode } from 'react';
import styled from 'styled-components';

import { Anchor } from 'src/components/common/atoms';
import { media } from 'src/styles';

type Props = {
  className?: string;
  isSmallColumnForMobile?: boolean;
  header?: ReactNode;
  content?: ReactNode;
  to?: string;
};

export const ContentGridItem: FC<Props> = ({
  className,
  isSmallColumnForMobile = false,
  header,
  content,
  to,
}) => (
  <article className={className ?? ''}>
    <StyledAnchor to={to ?? ''}>
      <Header>{header}</Header>
      <Content
        className={
          isSmallColumnForMobile ? 'small-margin-for-mobile' : undefined
        }
      >
        {content}
      </Content>
    </StyledAnchor>
  </article>
);

const Header = styled.header`
  line-height: ${({ theme }) => theme.lineHeight.heading.ja};
  font-size: ${({ theme }) => theme.font.size.large};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  flex-grow: 1;
`;

const Content = styled.div`
  margin-top: ${({ theme }) => theme.structure.margin.block.xsmall}px;
  ${media.lessThanIpadVertical`
    &.small-margin-for-mobile {
      margin-top: ${({ theme }) => theme.structure.margin.block.xxsmall}px;
    }
  `}
`;

const StyledAnchor = styled(Anchor)`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  height: 100%;

  ${Content} {
    position: relative;
    &::after {
      content: '';
      display: none;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  &:focus-visible {
    ${Content} {
      &::after {
        display: block;
      }
    }
  }

  @media (hover: hover) {
    &:hover {
      ${Content} {
        &::after {
          display: block;
        }
      }
    }
  }
`;

export default ContentGridItem;
