import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';

import { useSlug } from 'src/utils';

const query = graphql`
  query GetNewsHeaderNavigation {
    japanese: allContentfulNewsCategory(filter: { node_locale: { eq: "ja" } }) {
      nodes {
        title
        slug
        contentful_id
      }
    }
    english: allContentfulNewsCategory(filter: { node_locale: { eq: "en" } }) {
      nodes {
        title
        slug
        contentful_id
      }
    }
  }
`;

export const useNewsCategoryMenus = () => {
  const { japanese, english } =
    useStaticQuery<Queries.GetNewsHeaderNavigationQuery>(query);
  const { lang } = useSlug();
  return useMemo(() => {
    const list = lang === 'ja' ? japanese : english;
    return list.nodes.map((item) => ({
      id: item?.contentful_id,
      name: 'news',
      label: item?.title ?? 'no label',
      href: item?.slug ? `/news/category/${item.slug}/` : '/news/',
    }));
  }, [japanese, english, lang]);
};
