import { LanguageType } from 'src/types/custom-type';

type Props = {
  path: string;
  lang?: string | null;
};

const isLanguage = (arg: string): arg is LanguageType =>
  arg === 'ja' || arg === 'en';

export const getLangPath = ({ path, lang = 'ja' }: Props) => {
  if (lang && isLanguage(lang) && path.startsWith('/')) {
    return lang === 'ja' ? path : `/${lang}${path}`;
  }
  return path;
};
