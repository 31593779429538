import { PageProps } from 'gatsby';
import { useEffect, useState, type FC } from 'react';
import styled from 'styled-components';

import {
  Footer,
  Header,
  Navigation,
  PageGrid,
  ProviderManager,
} from 'src/components/common';
import {
  HeroImages,
  Description as HomeDescription,
} from 'src/components/home';
import { GlobalStyle, media } from 'src/styles';
import { PageContextType } from 'src/types/custom-type';
import { useIsMobile } from 'src/utils';

type Props = PageProps<any, PageContextType>;

// const getSlug = ({ pathname }: { pathname: string }): string => {
//   if (pathname === '/') {
//     return 'home';
//   } else {
//     const withoutTrailingSlashPath = pathname.replace(/\/$/, '');
//     return withoutTrailingSlashPath.substring(
//       withoutTrailingSlashPath.lastIndexOf('/') + 1
//     );
//   }
// };

// const useIsHome = (slug: string) => {
//   const [isHome, setIsHome] = useState(slug === 'home');
//   useEffect(() => {
//     setIsHome(slug === 'home');
//   }, [slug]);

//   return isHome;
// };

const useHasMounted = () => {
  // NOTE:
  // gatsby-plugin-layout上で、条件分岐でcomponentを出しわけする際、
  // html構造とrehydrationが一致させる必要があるので、
  // mountedの判定をいれる。
  // 一致していないと、rehydrationの際に、
  // domの挿入場所がおかしくなって、
  // 表示が崩れる。
  // reactのrehydrationの問題。
  // https://github.com/gatsbyjs/gatsby/issues/17914
  // https://joshwcomeau.com/react/the-perils-of-rehydration/
  // https://github.com/gatsbyjs/gatsby/issues/11738
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);
  return hasMounted;
};

const Layouts: FC<Props> = ({
  location: { pathname },
  children,
  pageContext: { lang, slug },
}) => {
  const currentLang = lang ?? (pathname.startsWith('/en') ? 'en' : 'ja');
  const isMobile = useIsMobile();
  const isHome = slug === 'home';
  const hasMounted = useHasMounted();

  return (
    <>
      <GlobalStyle />
      <ProviderManager slug={slug} pathname={pathname} lang={currentLang}>
        <Page className={currentLang !== 'ja' ? 'english' : undefined}>
          {isHome && <HeroImages />}
          <PageWithoutHeroImages
            className={hasMounted ? 'has-mounted' : undefined}
          >
            <StyledHeader isHome={isHome} />
            {isHome && <StyledHomeDescription />}
            <StyledPageGrid
              className={isHome ? 'is-home' : undefined}
              left={!isMobile && <StyledNavigationPc />}
              right={
                <Main role="main" id="main">
                  {children}
                </Main>
              }
            />
            <StyledFooter />
          </PageWithoutHeroImages>
        </Page>
      </ProviderManager>
    </>
  );
};

const StyledHeader = styled(Header)`
  ${media.lessThanIpadVertical`
    margin-left: -${({ theme }) => theme.structure.margin.vertical.sp}px;
    margin-right: -${({ theme }) => theme.structure.margin.vertical.sp}px;
    padding-left: ${({ theme }) => theme.structure.margin.vertical.sp}px;
    padding-right: ${({ theme }) => theme.structure.margin.vertical.sp}px;
    padding-top: ${({ theme }) => theme.structure.margin.top.sp}px;
  `}
  ${media.ipadVerticalOrMore`
    padding-top: ${({ theme }) => theme.structure.margin.top.pc}px;
    padding-bottom: 20px;
  `}
`;

const StyledHomeDescription = styled(HomeDescription)`
  ${media.lessThanIpadVertical`
    margin-top: ${({ theme }) => theme.structure.margin.block.large}px;
  `}
`;

// NOTE:
// デザイン上PCとSPで共通の場所にnavigationを置けないので分ける
// SP用はheader内で使用する
const StyledNavigationPc = styled(Navigation)`
  ${media.lessThanIpadVertical`
    display: none;
  `}
  ${media.ipadVerticalOrMore`
    position: sticky;
    top: ${({ theme }) => theme.structure.header.height.pc + 5}px;
  `}
`;

const Main = styled.main``;

const StyledPageGrid = styled(PageGrid)`
  flex-grow: 1;
  ${media.lessThanIpadVertical`
    &.is-home {
      margin-top: ${({ theme }) => theme.structure.margin.block.normal}px;
    }
    &:not(.is-home){
      margin-top: ${({ theme }) => theme.structure.margin.block.small}px;
    }
  `}

  ${media.ipadVerticalOrMore`
    &.is-home {
      margin-top: ${({ theme }) => theme.structure.margin.block.large}px;
    }
    &:not(.is-home) {
      margin-top: 135px;
    }
  `}
`;

const StyledFooter = styled(Footer)`
  margin-top: 60px;
  ${media.ipadVerticalOrMore`
    margin-top: 100px;
  `}
`;

const PageWithoutHeroImages = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  max-width: ${({ theme }) => theme.breakpoints.maxContent}px;

  /* NOTE:
    reactのrehydrationの問題の関係で、
    条件分岐コンポーネントのmountが発生するまでにラグがあるため、
    mountまでopacityをゼロにする。
   */
  transition: opacity 0.1s linear;
  &:not(.has-mounted) {
    opacity: 0;
  }
  &.has-mounted {
    opacity: 1;
  }
  ${media.lessThanIpadVertical`
    padding-bottom: ${({ theme }) => theme.structure.margin.bottom.sp}px;
    padding-left: ${({ theme }) => theme.structure.margin.vertical.sp}px;
    padding-right: ${({ theme }) => theme.structure.margin.vertical.sp}px;
  `}
  ${media.ipadVerticalOrMore`
    padding-bottom: ${({ theme }) => theme.structure.margin.bottom.pc}px;
    margin-left: auto;
    margin-right: auto;
    padding-left: ${({ theme }) => theme.structure.margin.vertical.pc}px;
    padding-right: ${({ theme }) => theme.structure.margin.vertical.pc}px;
  `}
`;

const Page = styled.div`
  position: relative;
  font-size: ${({ theme }) => theme.font.size.normal};
  line-height: ${({ theme }) => theme.lineHeight.main.ja};
  &.english {
    font-family: ${({ theme }) => theme.font.family.english};
  }
  ${media.ipadVerticalOrMore`
    font-size: ${({ theme }) => theme.font.size.normal};
  `}
`;

export default Layouts;
