import { useContext, useEffect } from 'react';

import {
  Context,
  ContextType,
  DispatchContext,
} from 'src/contexts/translation-context';

export const useTranslation = () => useContext(Context);

export const useTranslationDispath = () => useContext(DispatchContext);

export const useSetTranslation = (translation: ContextType) => {
  const { dispatch } = useContext(DispatchContext);
  useEffect(() => {
    dispatch && dispatch({ type: 'set', payload: translation });
  }, [dispatch, translation]);
};
