import { graphql } from 'gatsby';
import { type FC } from 'react';

import { Images } from 'src/components/common';

type Props = Queries.HomeEntryFragment & {
  className?: string;
};

export const fragment = graphql`
  fragment HomeEntry on ContentfulHome {
    images {
      ...ImagesItem
    }
  }
`;

export const Entry: FC<Props> = ({ className, images }) => (
  <div className={className ?? ''}>
    {images?.length && <Images images={images} />}
  </div>
);

export default Entry;
