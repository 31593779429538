import { css } from 'styled-components';
import { theme } from './theme';

const {
  ipadProHorizontal,
  // large,
  ipadHorizontal,
  ipadVertical,
  iphoneVertical,
} = theme.breakpoints;

const mediaFactory =
  (queryFeatures: string): typeof css =>
  (styles: any, ...interpolations: any[]) => css`
    @media ${queryFeatures} {
      ${css(styles, ...interpolations)};
    }
  `;

export const media = {
  // large
  // greaterThanLarge: mediaFactory(`(min-width: ${large + 1}px)`),
  // largeOrMore: mediaFactory(`(min-width: ${large}px)`),
  // largeOrLess: mediaFactory(`(max-width: ${large}px)`),
  // lessThanlarge: mediaFactory(`(max-width: ${large - 1}px)`),
  // iPad Pro Horizontal
  greaterThanIpadProHorizontal: mediaFactory(
    `(min-width: ${ipadProHorizontal + 1}px)`
  ),
  ipadProHorizontalOrMore: mediaFactory(`(min-width: ${ipadProHorizontal}px)`),
  ipadProHorizontalOrLess: mediaFactory(`(max-width: ${ipadProHorizontal}px)`),
  lessThanIpadProHorizontal: mediaFactory(
    `(max-width: ${ipadProHorizontal - 1}px)`
  ),
  //  iPad Horizontal
  greaterThanIpadHorizontal: mediaFactory(
    `(min-width: ${ipadHorizontal + 1}px)`
  ),
  ipadHorizontalOrMore: mediaFactory(`(min-width: ${ipadHorizontal}px)`),
  ipadHorizontalOrLess: mediaFactory(`(max-width: ${ipadHorizontal}px)`),
  lessThanIpadHorizontal: mediaFactory(`(max-width: ${ipadHorizontal - 1}px)`),
  // iPad Vertical
  greaterThanIpadVertical: mediaFactory(`(min-width: ${ipadVertical + 1}px)`),
  ipadVerticalOrMore: mediaFactory(`(min-width: ${ipadVertical}px)`),
  ipadVerticalOrLess: mediaFactory(`(max-width: ${ipadVertical}px)`),
  lessThanIpadVertical: mediaFactory(`(max-width: ${ipadVertical - 1}px)`),
  lessThanIpadVerticalLandscape: mediaFactory(
    `(max-width: ${ipadVertical - 1}px) and (orientation: landscape)`
  ),
  // iPhone Vertical
  greaterThanIphoneVertical: mediaFactory(
    `(min-width: ${iphoneVertical + 1}px)`
  ),
  iphoneVerticalOrMore: mediaFactory(`(min-width: ${iphoneVertical}px)`),
  iphoneVerticalOrLess: mediaFactory(`(max-width: ${iphoneVertical}px)`),
  lessThanIphoneVertical: mediaFactory(`(max-width: ${iphoneVertical - 1}px)`),
};
