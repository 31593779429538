import { css } from 'styled-components';

export const BorderBottomStyle = css`
  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: -4px;
    left: 0;
    width: 100%;
    border-bottom: 2px solid black;
  }
  &.border-type-thin {
    &::after {
      border-bottom-width: 1px;
    }
  }
`;
