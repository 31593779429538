import { type FC, type ReactNode } from 'react';
import styled from 'styled-components';

import { media } from 'src/styles';

type Props = {
  className?: string;
  children?: ReactNode;
  columnNumberPc?: 2 | 3;
  columnNumberSp?: 1 | 2;
};

export const ContentGrid: FC<Props> = ({
  className,
  children,
  columnNumberPc = 2,
  columnNumberSp = 1,
}) => (
  <Wrapper
    className={`${
      className ?? ''
    } column-pc-${columnNumberPc} column-sp-${columnNumberSp}`}
  >
    {children}
  </Wrapper>
);

const Wrapper = styled.div`
  display: grid;
  grid-column-gap: ${({ theme }) => theme.structure.margin.vertical.sp}px;
  grid-row-gap: ${({ theme }) => theme.structure.margin.block.small}px;
  ${media.lessThanIpadVertical`
    &.column-sp-2 {
      grid-template-columns: repeat(2, 1fr);
    }
  `}
  ${media.ipadVerticalOrMore`
    grid-column-gap: ${({ theme }) => theme.structure.margin.vertical.pc};
    grid-row-gap: ${({ theme }) => theme.structure.margin.block.normalSmall}px;
    &.column-pc-2 {
      grid-template-columns: repeat(2, 1fr);
    }
    &.column-pc-3 {
      /* grid-template-columns: repeat(3, 1fr); */
      grid-template-columns: repeat(auto-fill, minmax(258px, 1fr));
    }
  `}
`;

export default ContentGrid;
