import { type FC } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { Anchor, Button } from 'src/components/common/atoms';
import { media } from 'src/styles';

type Props = {
  className?: string;
  onClick?: () => void;
  to?: string;
  direction: 'up' | 'down' | 'right' | 'left';
  color?: string;
  disabled?: boolean;
  ariaLabel?: string;
};

export const ArrowButton: FC<Props> = ({
  className,
  onClick,
  to,
  direction,
  color = 'black',
  disabled = false,
  ariaLabel,
}) => {
  if (onClick) {
    return (
      <Wrapper className={[className, direction].join(' ')}>
        <StyledArrowButton
          onClick={onClick}
          disabled={disabled}
          aria-label={ariaLabel || undefined}
        >
          <StyledArrow className={direction}>
            <Line $backgroundColor={color} />
            <Line $backgroundColor={color} />
          </StyledArrow>
        </StyledArrowButton>
      </Wrapper>
    );
  } else if (to) {
    return (
      <Wrapper className={[className, direction].join(' ')}>
        <StyledArrowLink to={to}>
          <StyledArrow className={direction}>
            <Line $backgroundColor={color} />
            <Line $backgroundColor={color} />
          </StyledArrow>
        </StyledArrowLink>
      </Wrapper>
    );
  }
  return null;
};

const activeAnimationUp = keyframes`
  15% {
    transform: scale(0.8);
  }
  30% {
    transform: scale(1);
  }
`;

const hoverAnimationUp = keyframes`
  15% {
    transform: scaleX(0.5);
  }
  30% {
    transform: scaleX(1);
  }
`;

const hoverAnimationVertical = keyframes`
  15% {
    transform: scaleY(0.5);
  }
  30% {
    transform: scaleY(1);
  }
`;

const Line = styled.div<{
  $backgroundColor: string;
}>`
  position: absolute;
  z-index: 0;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`;

const StyledArrow = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  &.up,
  &.down {
    ${Line} {
      left: 50%;
      width: 2px;
      height: 100%;
    }
  }

  &.up {
    ${Line} {
      top: 0;
      transform-origin: center top;
      &:first-of-type {
        transform: rotate(40deg);
      }
      &:last-of-type {
        transform: rotate(-40deg);
      }
    }
  }

  &.down {
    ${Line} {
      bottom: 0;
      transform-origin: center bottom;
      &:first-of-type {
        transform: rotate(40deg);
      }
      &:last-of-type {
        transform: rotate(-40deg);
      }
    }
  }

  &.right,
  &.left {
    ${Line} {
      top: 50%;
      width: 140%;
      height: 2px;
      /* background-color: inherit; */
      &:first-of-type {
        transform: rotate(40deg);
      }
      &:last-of-type {
        transform: rotate(-40deg);
      }
    }
  }

  &.right {
    ${Line} {
      right: 0;
      transform-origin: right center;
    }
  }

  &.left {
    ${Line} {
      left: 0;
      transform-origin: left center;
    }
  }
`;

const StyledArrowBaseStyle = css`
  display: inline-block;
  width: 100%;
  height: 100%;
  --animation-duration: 0.6s;
  &:active {
    /* 押した時のアニメーション */
    animation-name: ${activeAnimationUp};
    animation-duration: var(--animation-duration);
  }
  &:focus-visible {
    /* hoverの時のアニメーション */
    ${StyledArrow} {
      &.up,
      &.down {
        animation-name: ${hoverAnimationUp};
        animation-duration: var(--animation-duration);
      }
      &.right,
      &.left {
        animation-name: ${hoverAnimationVertical};
        animation-duration: var(--animation-duration);
      }
    }
  }
  @media (hover: hover) {
    &:hover {
      /* hoverの時のアニメーション */
      ${StyledArrow} {
        &.up,
        &.down {
          animation-name: ${hoverAnimationUp};
          animation-duration: var(--animation-duration);
        }
        &.right,
        &.left {
          animation-name: ${hoverAnimationVertical};
          animation-duration: var(--animation-duration);
        }
      }
    }
  }
`;

const StyledArrowButton = styled(Button)`
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
  &.focus {
    outline: none;
  }
  /* NOTE:
  hoverの時に幅を固定にするため、
  ここに処理を書く
   */
  ${StyledArrowBaseStyle}
`;

const StyledArrowLink = styled(Anchor)`
  ${StyledArrowBaseStyle}
`;

const Wrapper = styled.div`
  --width: 34px;
  --height: 20px;
  ${media.ipadVerticalOrMore`
    --width: 50px;
    --height: 26px;
  `}

  &.up,
  &.down {
    width: var(--width);
    height: var(--height);
  }

  &.right,
  &.left {
    width: var(--height);
    height: var(--width);
  }
`;
