import NeueHelveticaRomanWoff2 from 'src/fonts/5664093/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2';
import NeueHelveticaRomanWoff from 'src/fonts/5664093/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff';
import NeueHelveticaBoldWoff2 from 'src/fonts/5664149/1f4274cd-2674-481e-9023-69e33ffca161.woff2';
import NeueHelveticaBoldWoff from 'src/fonts/5664149/5e8d493c-be01-44b9-962e-504a5cf10dd8.woff';
import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import { theme } from './theme';

export const GlobalStyle = createGlobalStyle`
  ${reset}
  @font-face{
    font-family: "Helvetica Neue LT";
    src: local("Helvetica Neue"), url(${NeueHelveticaRomanWoff2}) format("woff2"), url(${NeueHelveticaRomanWoff}) format("woff");
    font-weight: normal;
  }
  @font-face{
    font-family: "Helvetica Neue LT";
    src: local("Helvetica Neue Bold"), local("HelveticaNeue-Bold"), url(${NeueHelveticaBoldWoff2}) format("woff2"), url(${NeueHelveticaBoldWoff}) format("woff");
    font-weight: ${theme.font.weight.bold};
  }
  * {
    box-sizing: border-box;
    &:focus {
      outline: none;
    }
  }
  ::selection {
    background: ${theme.colors.lightGray};
  }

  html {
    font-size: 62.5%;
  }

  body {
    position: relative;
    margin: 0;
    padding: 0;
    font-family: ${theme.font.family.main};
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: ${theme.font.weight.bold};
  }

  button,
  input,
  select,
  textarea {
    appearance: none;
    border-radius: 0;
    background-color: ${theme.colors.bg};
    line-height: ${theme.lineHeight.main.ja};
    font-family: inherit;
    font-size: inherit;
    border: 1px solid black;
  }

  select::-ms-expand {
    display: none;
  }

  a {
    color: inherit;
    text-decoration: underline;
    &:focus-visible {
      text-decoration: none;
    }
    @media (hover:hover) {
      &:hover {
        text-decoration: none;
      }
    }
  }

  a,
  button,
  input,
  select,
  textarea {
    &:focus {
      outline: none;
    }
    &:focus-visible {
      outline: 1px solid black;
    }
  }

  img,
  svg {
    max-width: 100%;
    height: auto;
    vertical-align: top;
  }

  ul, ol {
    list-style: initial;
    padding-left: 1em;
  }
`;

export default GlobalStyle;
