type MakeTranslationProps = {
  slugs?: readonly {
    slug?: string | null;
    node_locale?: string | null;
  }[];
  basePath: string;
};

export const makeTranslation = ({ slugs, basePath }: MakeTranslationProps) => {
  const slugJa = slugs?.find((item) => item?.node_locale === 'ja');
  const slugEn = slugs?.find((item) => item?.node_locale === 'en');
  let basePathWithSlash = basePath;
  if (!basePathWithSlash.startsWith('/')) {
    basePathWithSlash = `/${basePathWithSlash}`;
  }
  if (!basePathWithSlash.endsWith('/')) {
    basePathWithSlash = `${basePathWithSlash}/`;
  }
  return {
    ja: slugJa?.slug ? `${basePathWithSlash}${slugJa.slug}` : null,
    en: slugEn?.slug ? `/en${basePathWithSlash}${slugEn.slug}` : null,
  };
};
