import type {
  NodeRenderer,
  Options,
} from '@contentful/rich-text-react-renderer';
import { INLINES } from '@contentful/rich-text-types';
import { graphql } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { useCallback, useMemo, type FC } from 'react';

import { Anchor } from 'src/components/common/atoms';
import { getLangPath, useSlug } from 'src/utils';

type RenderRichTextArg = Parameters<typeof renderRichText>[0];
type RenderRichTextArgPartialReferences = {
  raw?: RenderRichTextArg['raw'] | null;
  readonly references?: Queries.Maybe<
    ReadonlyArray<Queries.Maybe<Queries.CustomRichTextReferencesFragment>>
  >;
};

export type Props = {
  children?: never;
  document: RenderRichTextArgPartialReferences;
};

export const fragment = graphql`
  fragment CustomRichTextReferences on ContentfulAllPostsReference {
    ... on ContentfulAbout {
      contentful_id
      __typename
      slug
    }
    ... on ContentfulAfterCoconogacco {
      contentful_id
      __typename
      slug
    }
    ... on ContentfulClass {
      contentful_id
      __typename
      slug
    }
    ... on ContentfulContact {
      contentful_id
      __typename
      slug
    }
    ... on ContentfulHome {
      contentful_id
      __typename
      slug
    }
    ... on ContentfulNews {
      contentful_id
      __typename
      slug
    }
    ... on ContentfulNewsCategory {
      contentful_id
      __typename
      slug
    }
    ... on ContentfulQAndA {
      contentful_id
      __typename
      slug
    }
    ... on ContentfulStudentWorks {
      contentful_id
      __typename
      slug
    }
    ... on ContentfulStudentsInvited {
      contentful_id
      __typename
      slug
    }
    ... on ContentfulTutors {
      contentful_id
      __typename
      slug
    }
    ... on ContentfulAsset {
      contentful_id
      __typename
      gatsbyImageData(quality: 90)
    }
  }
`;

// https://www.contentful.com/developers/docs/tutorials/general/rich-text-and-gatsby/
// https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-source-contentful/CHANGELOG.md#400-next0-2020-11-09
const useOptions = () => {
  const linkUrlResolver = useCallback<NodeRenderer>((node, children) => {
    const { data } = node;
    return <Anchor to={data.uri}>{children}</Anchor>;
  }, []);

  const { lang } = useSlug();

  const linkEntryResolver = useCallback<NodeRenderer>(
    (node, children) => {
      const { content, data } = node;
      if (data?.target) {
        const target = data.target as Queries.CustomRichTextReferencesFragment;
        const { __typename } = target;
        let path = '';
        if ('slug' in target) {
          const { slug } = target;
          if (__typename === 'ContentfulHome') {
            path = getLangPath({ path: `/`, lang });
          } else if (
            __typename === 'ContentfulAbout' ||
            __typename === 'ContentfulContact' ||
            __typename === 'ContentfulQAndA' ||
            __typename === 'ContentfulStudentsInvited'
          ) {
            path = getLangPath({ path: `/${slug}`, lang });
          } else if (__typename === 'ContentfulAfterCoconogacco') {
            path = getLangPath({ path: `/after-coconogacco/${slug}`, lang });
          } else if (__typename === 'ContentfulClass') {
            path = getLangPath({ path: `/class/${slug}`, lang });
          } else if (__typename === 'ContentfulNews') {
            path = getLangPath({ path: `/news/${slug}`, lang });
          } else if (__typename === 'ContentfulNewsCategory') {
            path = getLangPath({ path: `/news/category/${slug}`, lang });
          } else if (__typename === 'ContentfulStudentWorks') {
            path = getLangPath({ path: `/student-works/${slug}`, lang });
          } else if (__typename === 'ContentfulTutors') {
            path = getLangPath({ path: `/tutors/${slug}`, lang });
          }
        }
        return <Anchor to={path}>{children}</Anchor>;
      }
      return children;
    },
    [lang]
  );

  const assetLinkResolver = useCallback<NodeRenderer>((node, children) => {
    const { target } = node.data;
    const href = target?.file?.url as string | undefined;
    if (!href) {
      return children;
    }
    return (
      <a href={`https:${href}`} target="_blank" rel="noopenner noreferrer">
        {children}
      </a>
    );
  }, []);

  // const embedAssetResolver = useCallback<NodeRenderer>((node) => {
  //   const { localFile } = node.data.target;
  //   if (!localFile) {
  //     // asset is not an image
  //     return null;
  //   }
  //   // NOTE:
  //   // 未確認
  //   return <Img file={localFile} />;
  // }, []);

  return useMemo<Options>(
    () => ({
      renderNode: {
        [INLINES.HYPERLINK]: linkUrlResolver,
        [INLINES.ENTRY_HYPERLINK]: linkEntryResolver,
        [INLINES.ASSET_HYPERLINK]: assetLinkResolver,
        // [BLOCKS.EMBEDDED_ASSET]: embedAssetResolver,
      },
      // NOTE:
      // 改行を<br>に変換
      // https://github.com/contentful/rich-text/issues/96
      renderText: (text) =>
        text
          .split('\n')
          .flatMap((text, i) => [
            i > 0 && <br key={`line-break-${i}`} />,
            text,
          ]),
    }),
    [linkUrlResolver, linkEntryResolver, assetLinkResolver]
  );
};
export const RichTextRenderer: FC<Props> = ({ document }) => {
  const options = useOptions();
  const { raw, references } = document;
  if (!raw) {
    return null;
  }

  // NOTE:
  // referencesがない場合ダミーを追加
  const customDocument = references
    ? // NOTE:
      // ここ型操作必要。
      // とりあえずany入れておく
      ({ raw, references } as any)
    : ({ raw, references: [] } as RenderRichTextArg);

  return <>{renderRichText(customDocument, options)}</>;
};

export default RichTextRenderer;
