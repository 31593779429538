import { type FC } from 'react';
import styled from 'styled-components';

import { Anchor } from 'src/components/common/atoms';
import { ScreenReaderTextStyle } from 'src/components/common/screen-reader-text';
import type { MenuProps } from 'src/constants';
import { BorderBottomStyle, media } from 'src/styles';
import { useIsMobile } from 'src/utils';

export type Props = {
  className?: string;
  title: string;
  titleTag?: 'h1' | 'p';
  menuList?: MenuProps[];
};

export const PageHeader: FC<Props> = ({
  className,
  title,
  titleTag = 'h1',
  menuList,
}) => {
  const isMobile = useIsMobile();
  return (
    <Wrapper className={className ?? ''}>
      {title && (
        <Title as={titleTag}>
          <TitleInner>{title}</TitleInner>
        </Title>
      )}
      {isMobile && menuList && (
        <MenuList>
          {menuList.map((menuItem, index) => (
            <MenuListItem
              key={menuItem?.id ?? `page-header-menu-item-${index}`}
            >
              <StyledAnchor
                to={menuItem.href}
                activeClassName="current"
                partiallyActive={true}
              >
                {menuItem.label}
              </StyledAnchor>
            </MenuListItem>
          ))}
        </MenuList>
      )}
    </Wrapper>
  );
};

const TitleInner = styled.span`
  position: relative;
  ${BorderBottomStyle}
`;
const Title = styled.h1`
  ${media.lessThanIpadVertical`
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: ${({ theme }) => theme.font.size.large};
    
  `}
  ${media.ipadVerticalOrMore`
    ${ScreenReaderTextStyle}
  `}
`;

const StyledAnchor = styled(Anchor)`
  text-decoration: none;

  &.current,
  &:focus-visible {
    text-decoration: underline;
  }

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
`;

const MenuListItem = styled.li`
  margin-left: 0.5em;
  margin-right: 0.5em;
`;

const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  text-align: center;
  letter-spacing: ${({ theme }) => theme.letterSpacing.menu};
`;

export default PageHeader;
