import 'styled-components';

export const theme = {
  font: {
    family: {
      main:
        '"游ゴシック体", "YuGothic", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif',
      english: '"Helvetica Neue LT", "Helvetica Neue", "Helvetica", sans-serif',
    },
    size: {
      xLarge: '1.8rem',
      large: '1.6rem',
      normalEn: '1.5rem',
      normal: '1.4rem',
      small: '1.2rem',
    },
    weight: {
      bold: 600,
    },
  },
  letterSpacing: {
    menu: '0.07em',
  },
  lineHeight: {
    main: {
      ja: 2,
    },
    heading: {
      ja: 1.6,
    },
  },
  colors: {
    bg: '#fff',
    font: '#000',
    gray: '#9FA0A0',
    lightGray: '#cecece',
  },
  structure: {
    margin: {
      top: {
        sp: 30,
        pc: 47,
      },
      bottom: {
        sp: 20,
        pc: 30,
      },
      vertical: {
        sp: 18,
        pc: 23,
      },
      grid: {
        sp: 15,
        pc: 23,
      },
      block: {
        large: 60 - 5,
        normal: 45 - 5,
        normalSmall: 42 - 5,
        small: 30 - 5,
        xsmall: 23 - 5,
        xxsmall: 16 - 5,
      },
    },
    header: {
      height: {
        sp: 81,
        pc: 120,
      },
    },
  },
  breakpoints: {
    iphoneVertical: 375,
    ipadVertical: 768,
    ipadHorizontal: 1024,
    ipadProHorizontal: 1300,
    // maxContent: 1400,
    maxContent: 1200,
  },
};

type ThemeType = typeof theme;

declare module 'styled-components' {
  export interface DefaultTheme extends ThemeType {}
}

export default theme;
