import { type FC } from 'react';
import styled from 'styled-components';

import { Anchor } from 'src/components/common/atoms';
import { useTranslation } from 'src/utils';

type Props = {
  className?: string;
};

export const Languages: FC<Props> = ({ className }) => {
  const { ja, en } = useTranslation();
  return (
    <Wrapper className={className ?? ''}>
      <Item>
        <StyledAnchor to={ja ?? '/'} aria-label="Japanese">
          J
        </StyledAnchor>
      </Item>
      <Slash aria-hidden="true">/</Slash>
      <Item>
        <StyledAnchor to={en ?? '/en/'} aria-label="English">
          E
        </StyledAnchor>
      </Item>
    </Wrapper>
  );
};

const StyledAnchor = styled(Anchor)`
  text-decoration: none;
  &.current,
  &:focus-visible {
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  @media (hover: hover) {
    &:hover {
      font-weight: ${({ theme }) => theme.font.weight.bold};
    }
  }
`;

const Slash = styled.li`
  margin-left: 0.25em;
  margin-right: 0.25em;
`;

const Item = styled.li``;

const Wrapper = styled.ul`
  list-style: none;
  padding: 0;
  line-height: 1;
  display: flex;
`;

export default Languages;
