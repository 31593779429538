import {
  GatsbyImage,
  GatsbyImageProps,
  getImage,
  type IGatsbyImageData,
  type ImageDataLike,
} from 'gatsby-plugin-image';
import { useCallback, useMemo, useRef, type FC } from 'react';
import styled from 'styled-components';

export type Props = Omit<GatsbyImageProps, 'image' | 'alt'> & {
  className?: string;
  file?: Queries.Maybe<
    | {
        readonly childImageSharp: Queries.Maybe<
          Pick<Queries.ImageSharp, 'gatsbyImageData'>
        >;
      }
    | { readonly gatsbyImageData: IGatsbyImageData | null }
  >;
  alt?: string | null;
  loading?: 'lazy' | 'eager';
  fadeInDuration?: number;
  onLoad?(image: HTMLImageElement | null): void;
};

const useImage = (file?: Props['file']) =>
  useMemo(() => {
    if (!file) {
      return null;
    }
    if ('gatsbyImageData' in file) {
      return file.gatsbyImageData;
    }
    return getImage(file as ImageDataLike);
  }, [file]);

export const Img: FC<Props> = ({
  className,
  file,
  alt,
  loading = 'lazy',
  onLoad,
  fadeInDuration,
  ...rest
}) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const handleLoaded = useCallback(() => {
    let image: HTMLImageElement | null = null;
    if (wrapperRef.current) {
      image = wrapperRef.current.querySelector('img');
    }
    if (onLoad) {
      onLoad(image);
    }
  }, [onLoad]);

  const image = useImage(file);
  if (!image) {
    return null;
  }
  const aspectRatio = image?.width / image?.height;
  const aspectRatioClass = aspectRatio
    ? aspectRatio > 1
      ? 'horizontal-image'
      : 'vertical-image'
    : '';

  return (
    <Wrapper
      className={`${className || ''} ${aspectRatioClass}`}
      ref={wrapperRef}
      $fadeInDuration={fadeInDuration}
    >
      <GatsbyImage
        image={image}
        alt={alt ?? ''}
        onLoad={handleLoaded}
        loading={loading}
        {...rest}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $fadeInDuration: Props['fadeInDuration'] }>`
  .gatsby-image-wrapper [data-main-image] {
    ${({ $fadeInDuration }) =>
      $fadeInDuration
        ? `transition-duration: ${$fadeInDuration}ms !important;`
        : null};
  }
`;

export default Img;
