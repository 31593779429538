import { Unpacked } from 'src/types/custom-type';
export const DEFAULT_MENUS = {
  MAIN: [
    {
      name: 'about',
      label: 'about',
      href: '/about',
      children: [
        {
          name: 'philosophy',
          label: 'philosophy',
          href: '#philosophy',
        },
        {
          name: 'comment',
          label: 'comment',
          href: '#comment',
        },
        {
          name: 'history',
          label: 'history',
          href: '#history',
        },
        {
          name: 'access',
          label: 'access',
          href: '#access',
        },
      ],
    },
    {
      name: 'class',
      label: 'class',
      href: '/class',
    },
    {
      name: 'tutors',
      label: 'tutors',
      href: '/tutors',
    },
    {
      name: 'student-works',
      label: 'student works',
      href: '/student-works',
    },
    {
      name: 'after-coconogacco',
      label: 'after coconogacco',
      href: '/after-coconogacco',
    },
    {
      name: 'news',
      label: 'news',
      href: '/news',
    },
  ],
  SUB: [
    {
      name: 'q-and-a',
      label: 'Q&A',
      href: '/q-and-a',
    },
    {
      name: 'contact',
      label: 'contact',
      href: '/contact',
    },
    {
      name: 'instagram',
      label: 'instagram',
      href: 'https://www.instagram.com/coconogacco/',
    },
  ],
};

type MenusProps = typeof DEFAULT_MENUS.MAIN;
// NOTE:
// idを含めるために、
// childrenを定義し直す。
export type MenuProps = Omit<Unpacked<MenusProps>, 'children'> & {
  id?: string | null;
  children?: MenuProps[];
};
