import { type FC } from 'react';
import styled from 'styled-components';

import { PageGrid } from 'src/components/common';
import { Navigation } from 'src/components/common/navigation';
import { media } from 'src/styles';
import { useIsMobile } from 'src/utils';
import { Banner } from './banner';
import { Title } from './title';

type Props = {
  className?: string;
  isHome: boolean;
};

export const Header: FC<Props> = ({ className, isHome }) => {
  const isMobile = useIsMobile();
  return (
    <Wrapper className={className ?? ''} role="banner">
      <PageGrid right={<StyledTitle isHome={isHome} />} />
      <StyledBanner />
      {isMobile && <StyledMobileNavigation />}
    </Wrapper>
  );
};

const StyledTitle = styled(Title)`
  ${media.ipadVerticalOrMore`
    position: relative;
    z-index: 11;
  `}
`;

const StyledBanner = styled(Banner)`
  position: absolute;
  z-index: 10;
  ${media.lessThanIpadVertical`
    top: 0;
    right: 2px;
  `}
  ${media.ipadVerticalOrMore`
    top: 8px; 
    left: -39px;
  `}
`;

const StyledMobileNavigation = styled(Navigation)`
  ${media.lessThanIpadVertical`
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
  `}
`;

const Wrapper = styled.header`
  background-color: white;
  position: sticky;
  z-index: 10;
  top: 0;
  ${media.lessThanIpadVertical`
    height: ${({ theme }) => theme.structure.header.height.sp}px;
  `}
  ${media.ipadVerticalOrMore`
    height: ${({ theme }) => theme.structure.header.height.pc}px;
  `}
`;

export default Header;
