import { graphql, useStaticQuery } from 'gatsby';
import { useMemo, type FC } from 'react';
import styled from 'styled-components';

import { PageGrid, RichTextRenderer } from 'src/components/common';
import { useSlug } from 'src/utils';

type Props = {
  className?: string;
};

export const query = graphql`
  query GetHomeDescription {
    allContentfulHome {
      nodes {
        node_locale
        description {
          raw
        }
      }
    }
  }
`;

const useDescription = (
  data: Queries.GetHomeDescriptionQuery['allContentfulHome']
) => {
  const { lang } = useSlug();
  return useMemo(() => {
    const currentNode = data?.nodes.find((node) => node?.node_locale === lang);
    return currentNode?.description;
  }, [lang, data]);
};

export const Description: FC<Props> = ({ className }) => {
  const { allContentfulHome } =
    useStaticQuery<Queries.GetHomeDescriptionQuery>(query);
  const description = useDescription(allContentfulHome);
  return (
    <PageGrid
      className={className ?? ''}
      right={
        description && (
          <DescriptionWrapper>
            <RichTextRenderer document={description} />
          </DescriptionWrapper>
        )
      }
    />
  );
};

const DescriptionWrapper = styled.div`
  font-size: ${({ theme }) => theme.font.size.large};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

export default Description;
