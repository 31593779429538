import { useCallback, useEffect, useState, type FC } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import styled, { keyframes } from 'styled-components';

import { ArrowButton } from 'src/components/common';

type Props = {
  className?: string;
};

const useHasShown = () => {
  const [hasShown, setHasShown] = useState(false);
  useEffect(() => {
    const timerId = setTimeout(() => {
      setHasShown(true);
    }, 10);
    return () => {
      clearTimeout(timerId);
    };
  }, []);
  return hasShown;
};

const useArrowButtonClick = () =>
  useCallback(() => {
    const scrollValue = window.innerHeight;
    scroll.scrollTo(scrollValue, { duration: 600, smooth: 'easeOutQuint' });
  }, []);

export const ScrollButton: FC<Props> = ({ className }) => {
  const hasShown = useHasShown();
  const handleArrowButtonClick = useArrowButtonClick();
  return (
    <div className={className ?? ''}>
      <StyledArrowButton
        className={hasShown ? 'has-shown' : ''}
        direction="down"
        onClick={handleArrowButtonClick}
      />
    </div>
  );
};

const animation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-7px);
  }
  10% {
    opacity: 1;
    transform: translateY(0);
  }
  80% {
    opacity: 1;
    transform: translateY(0);
  }
  90% {
    opacity: 0;
    transform: translateY(7px);
  }
  100% {
    opacity: 0;
    transform: translateY(7px);
  }
`;

const StyledArrowButton = styled(ArrowButton)`
  opacity: 0;
  &.has-shown {
    opacity: 1;
    transform: translateY(0);
    animation-name: ${animation};
    animation-duration: 2.7s;
    animation-timing-function: ease-in;
    animation-iteration-count: infinite;
    &:hover {
      animation: none;
    }
  }
`;

export default ScrollButton;
