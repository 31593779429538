import { graphql } from 'gatsby';
import { type FC } from 'react';
import styled from 'styled-components';

import { ContentGridItem } from 'src/components/common';
import { Img } from 'src/components/common/atoms/img';

type Props = {
  className?: string;
  title: string;
  meta?: string | null;
  image?: Queries.PeopleArchiveEntryImageFragment;
  to: string;
};

export const fragment = graphql`
  fragment PeopleArchiveEntryImage on ContentfulAsset {
    gatsbyImageData(
      sizes: "(min-width: 1200px) 275px, (min-width: 768px) calc((100vw - 69px) * 0.75 * 0.5), calc((100vw - 36px - 18px) * 0.5)"
      aspectRatio: 0.66
      quality: 90
    )
  }
`;

export const Entry: FC<Props> = ({ className, title, meta, image, to }) => (
  <ContentGridItem
    className={className ?? ''}
    isSmallColumnForMobile={true}
    header={
      <>
        <h2>{title}</h2>
        {meta && (
          <Meta>
            <MetaText>{meta}</MetaText>
          </Meta>
        )}
      </>
    }
    content={<Img file={image} alt={`Picture of ${title}`} />}
    to={to}
  />
);

const MetaText = styled.span`
  &::before {
    content: '(';
    margin-right: 0.2em;
  }
  &::after {
    content: ')';
    margin-left: 0.2em;
  }
`;

const Meta = styled.p`
  font-weight: normal;
  font-size: ${({ theme }) => theme.font.size.normal};
`;

// const StyledImg = styled(Img)`
//   .gatsby-image-wrapper {
//     > div {
//       padding-bottom: ${(3 / 2) * 100}% !important;
//     }
//   }
// `;

export default Entry;
