import { type FC } from 'react';
import styled from 'styled-components';

import { Anchor } from 'src/components/common/atoms';

export type Props = {
  className?: string;
  nextPath?: string;
  previousPath?: string;
  indexPath?: string;
};

export const Pagination: FC<Props> = ({
  className,
  nextPath,
  previousPath,
  indexPath,
}) => {
  if (!nextPath && !previousPath && !indexPath) {
    return null;
  }
  return (
    <List className={className ?? ''}>
      <ListItem aria-hidden={!previousPath}>
        {previousPath && <StyledAnchor to={previousPath}>prev</StyledAnchor>}
      </ListItem>
      <ListItem aria-hidden={!indexPath}>
        {indexPath && <StyledAnchor to={indexPath}>index</StyledAnchor>}
      </ListItem>
      <ListItem aria-hidden={!nextPath}>
        {nextPath && <StyledAnchor to={nextPath}>next</StyledAnchor>}
      </ListItem>
    </List>
  );
};

const StyledAnchor = styled(Anchor)`
  text-decoration: none;
  --hover-opacity: 0.5;
  &:focus-visible {
    opacity: var(--hover-opacity);
  }

  @media (hover: hover) {
    &:hover {
      opacity: var(--hover-opacity);
    }
  }
`;

const ListItem = styled.li`
  min-width: 2em;
  font-size: ${({ theme }) => theme.font.size.small};
  text-align: center;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
`;

export default Pagination;
