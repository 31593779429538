import parse, { Element, domToReact, type DOMNode } from 'html-react-parser';
import { useMemo, type FC } from 'react';

import { Anchor } from 'src/components/common/atoms';

type Props = {
  html: string;
};

export const HtmlParser: FC<Props> = ({ html }) => {
  const options = useMemo(
    () => ({
      replace: (domNode: DOMNode) => {
        if (domNode instanceof Element) {
          const { name, attribs, children } = domNode;
          if (name === 'a') {
            if (!attribs?.href || !children) {
              return;
            }
            return (
              <Anchor to={attribs.href}>
                {domToReact(children as DOMNode[], options)}
              </Anchor>
            );
          }
        }
        return;
      },
      trim: true,
    }),
    []
  );
  return <>{parse(html, options)}</>;
};

export default HtmlParser;
