import { useReducer, type FC, type ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';

import { Context as SlugContext } from 'src/contexts/slug-context';
import {
  Context as TranslationContext,
  DispatchContext as TranslationDispatchContext,
  Reducer as TranslationReducer,
  initialValue as translationInitialValue,
} from 'src/contexts/translation-context';
import { theme as styleTheme } from 'src/styles';
import { LanguageType } from 'src/types/custom-type';

type Props = {
  children?: ReactNode;
  slug: string;
  pathname: string;
  lang: LanguageType;
};

export const ProviderManager: FC<Props> = ({
  slug,
  pathname,
  lang,
  children,
}) => {
  const [translationState, translationDispatch] = useReducer(
    TranslationReducer,
    {
      ...translationInitialValue,
      ...{ [lang]: pathname },
    }
  );
  return (
    <ThemeProvider theme={styleTheme}>
      <SlugContext.Provider value={{ slug, pathname, lang }}>
        <TranslationContext.Provider value={translationState}>
          <TranslationDispatchContext.Provider
            value={{ dispatch: translationDispatch }}
          >
            {children}
          </TranslationDispatchContext.Provider>
        </TranslationContext.Provider>
      </SlugContext.Provider>
    </ThemeProvider>
  );
};

export default ProviderManager;
