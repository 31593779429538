import { createContext } from 'react';

import { LanguageType } from 'src/types/custom-type';

export const initialValue = {
  slug: '',
  pathname: '',
  lang: 'ja' as LanguageType,
};

type ContextType = typeof initialValue;

export const Context = createContext<ContextType>(initialValue);

export default Context;
