import { graphql } from 'gatsby';
import { type FC, type ReactNode } from 'react';
import styled from 'styled-components';

import { EntryTitleSingle } from 'src/components/common';
import { Img } from 'src/components/common/atoms/img';
import { media } from 'src/styles';

type Props = {
  className?: string;
  children?: ReactNode;
  title: string;
  meta?: string | null;
  image?: Queries.PeopleSingleEntryImageFragment | null;
};

export const fragment = graphql`
  fragment PeopleSingleEntryImage on ContentfulAsset {
    gatsbyImageData(
      sizes: "(min-width: 1200px) 860px, (min-width: 768px) calc((100vw - 69px) * 0.75), calc(100vw - 46px)"
      quality: 90
    )
  }
`;

export const Entry: FC<Props> = ({
  className,
  children,
  title,
  meta,
  image,
}) => (
  <Wrapper className={className ?? ''}>
    <Header>
      <EntryTitleSingle>{title}</EntryTitleSingle>
      {meta && <Meta>({meta})</Meta>}
      {image && (
        <StyledImg file={image} alt={`Picture of ${title}`} loading="eager" />
      )}
    </Header>
    {children && <Content>{children}</Content>}
  </Wrapper>
);

const Meta = styled.p`
  font-size: ${({ theme }) => theme.font.size.normal};
`;

const StyledImg = styled(Img)`
  margin-top: var(--margin-top);
  ${media.ipadVerticalOrMore`
    &.vertical-image {
      width: calc((100% / 6) * 4);
      margin-left: auto;
      margin-right: auto;
    }
  `}
`;

const Header = styled.div``;

const Content = styled.div`
  margin-top: var(--margin-top);
`;

const Wrapper = styled.div`
  --margin-top: ${({ theme }) => theme.structure.margin.block.small}px;
  ${media.ipadVerticalOrMore`
    --margin-top: ${({ theme }) => theme.structure.margin.block.normalSmall}px;
  `}
`;

export default Entry;
