import { type FC } from 'react';
import styled from 'styled-components';

import { ScreenReaderText } from 'src/components/common';
import { Anchor } from 'src/components/common/atoms';
import Logo from 'src/images/logo.svg';
import { media } from 'src/styles';
import { useSlug } from 'src/utils';

type Props = {
  className?: string;
  isHome: boolean;
};

export const Title: FC<Props> = ({ className, isHome }) => {
  const { lang } = useSlug();
  return (
    <Wrapper className={`${className || ''}`} as={isHome ? 'h1' : 'p'}>
      <StyledAnchor to={lang === 'ja' ? `/` : `/${lang}/`} lang={lang}>
        <ScreenReaderText>
          {lang === 'ja' ? 'coconogacco ここのがっこう' : 'coconogacco'}
        </ScreenReaderText>
        <Logo />
      </StyledAnchor>
    </Wrapper>
  );
};

const StyledAnchor = styled(Anchor)`
  text-decoration: none;
  display: inline-block;
  &:focus {
    text-decoration: none;
  }
  @media (hover: hover) {
    &:hover {
      text-decoration: none;
    }
  }
  ${media.lessThanIpadVertical`
    width: 195px;
  `}
  ${media.ipadVerticalOrMore`
    width: 280px;
  `}
`;

const Wrapper = styled.h1`
  line-height: 1;
  ${media.lessThanIpadVertical`
    text-align: center;
  `}
`;

export default Title;
