import { type FC, type ReactNode } from 'react';
import styled from 'styled-components';

import { HtmlParser } from './html-parser';
import type { Props as RichTextProps } from './rich-text-renderer';
import { RichTextRenderer } from './rich-text-renderer';

type Props = {
  className?: string;
  document: RichTextProps['document'] | string;
  headerChildren?: ReactNode;
  footerChildren?: ReactNode;
};

const isRichTextDocument = (
  arg: Props['document']
): arg is RichTextProps['document'] => typeof arg !== 'string';

export const RichText: FC<Props> = ({
  className,
  document,
  headerChildren,
  footerChildren,
}) => (
  <Wrapper className={className ?? ''}>
    {headerChildren && headerChildren}
    {isRichTextDocument(document) && <RichTextRenderer document={document} />}
    {!isRichTextDocument(document) && <HtmlParser html={document} />}
    {footerChildren && footerChildren}
  </Wrapper>
);

const Wrapper = styled.div`
  * {
    &:not(li):not(h2):not(h3):not(h4):not(h5):not(h6) + * {
      margin-top: 2em;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  p:empty {
    display: none;
  }
`;

export default RichText;
