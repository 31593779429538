import { graphql, useStaticQuery } from 'gatsby';
import { useMemo, type FC } from 'react';
import styled from 'styled-components';

import { Img } from 'src/components/common/atoms';
import Logo from 'src/images/logo.svg';
import { media } from 'src/styles';
import { getRandomInt } from 'src/utils';
import { ScrollButton } from './scroll-button';

type Props = {
  className?: string;
};

export const query = graphql`
  query GetHomeHeroImages {
    contentfulHome(slug: { eq: "home" }) {
      heroImages {
        id
        gatsbyImageData(layout: FULL_WIDTH, quality: 90, placeholder: NONE)
      }
    }
  }
`;

const useImage = (data: Queries.GetHomeHeroImagesQuery['contentfulHome']) =>
  useMemo(() => {
    const images = data?.heroImages;
    if (!images?.length) {
      return null;
    }
    const image = images[getRandomInt(images.length)];
    return image;
  }, [data]);

export const HeroImages: FC<Props> = ({ className }) => {
  const { contentfulHome } =
    useStaticQuery<Queries.GetHomeHeroImagesQuery>(query);
  const image = useImage(contentfulHome);
  return (
    <Wrapper className={className ?? ''}>
      <LogoWrapper>
        <Logo aria-label="coconogacco" />
      </LogoWrapper>
      <StyledImg
        file={image}
        alt="Picure of coconogacco"
        fadeInDuration={500}
        loading="eager"
      />
      <StyledScrollButton />
    </Wrapper>
  );
};

const LogoWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  > svg {
    height: auto;
  }
  ${media.lessThanIpadVertical`
    > svg {
      width: 279px;
    }
  `}
  ${media.ipadVerticalOrMore`
    > svg {
      width: 558px;
    }
  `}
`;

const StyledImg = styled(Img)`
  height: 100%;
  > div {
    height: 100%;
  }
`;

const StyledScrollButton = styled(ScrollButton)`
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  ${media.lessThanIpadVertical`
    bottom: 15px;
  `}
  ${media.ipadVerticalOrMore`
    bottom: 30px;
  `}
`;

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  /* TODO:
    mobileでの100vhは調整する。
   */
  height: 100vh;
  list-style: none;
  padding: 0;
`;

export default HeroImages;
