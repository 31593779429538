import { type FC } from 'react';

type Props = {
  className?: string;
};

export const NoContent: FC<Props> = ({ className }) => (
  <div className={className ?? ''}>No content.</div>
);

export default NoContent;
