import { useCallback, useMemo, type FC, type ReactNode } from 'react';
import { Events, Link } from 'react-scroll';
import type { ReactScrollLinkProps } from 'react-scroll/modules/components/Link';

import { theme } from 'src/styles';
import { useIsMobile } from 'src/utils';

type Props = ReactScrollLinkProps & {
  className?: string;
  children?: ReactNode;
};

export const ScrollLink: FC<Props> = ({ className, children, ...rest }) => {
  const handleClick = useCallback(() => {
    Events.scrollEvent.register('end', (to, element) => {
      if (element) {
        const htmlElement = element as HTMLElement;
        htmlElement.tabIndex = -1;
        htmlElement.focus({ preventScroll: true });
      }
      Events.scrollEvent.remove('end');
    });
  }, []);
  const isMobile = useIsMobile();
  const offset = useMemo(
    () =>
      isMobile
        ? -theme.structure.header.height.sp
        : -theme.structure.header.height.pc,
    [isMobile]
  );
  return (
    <Link
      className={className ?? ''}
      {...rest}
      offset={offset}
      onClick={handleClick}
    >
      {children}
    </Link>
  );
};

export default ScrollLink;
