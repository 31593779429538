import { createContext, type Dispatch, type Reducer } from 'react';

export const initialValue = {
  ja: null,
  en: null,
};

export type ContextType = {
  ja: string | null;
  en: string | null;
};

export const Context = createContext<ContextType>(initialValue);

type ReducerType = { type: string; payload: ContextType };

type DispatchContextType = {
  dispatch: Dispatch<ReducerType> | null;
};

export const DispatchContext = createContext<DispatchContextType>({
  dispatch: null,
});

export const Reducer: Reducer<ContextType, ReducerType> = (state, action) => {
  switch (action.type) {
    case 'set':
      return { ...state, ...action.payload };
    default:
      throw new Error('LoaderReducer error');
  }
};

export default Context;
