exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-after-coconogacco-archive-tsx": () => import("./../../../src/templates/after-coconogacco-archive.tsx" /* webpackChunkName: "component---src-templates-after-coconogacco-archive-tsx" */),
  "component---src-templates-after-coconogacco-single-tsx": () => import("./../../../src/templates/after-coconogacco-single.tsx" /* webpackChunkName: "component---src-templates-after-coconogacco-single-tsx" */),
  "component---src-templates-class-archive-tsx": () => import("./../../../src/templates/class-archive.tsx" /* webpackChunkName: "component---src-templates-class-archive-tsx" */),
  "component---src-templates-class-single-tsx": () => import("./../../../src/templates/class-single.tsx" /* webpackChunkName: "component---src-templates-class-single-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-news-archive-tsx": () => import("./../../../src/templates/news-archive.tsx" /* webpackChunkName: "component---src-templates-news-archive-tsx" */),
  "component---src-templates-news-category-archive-tsx": () => import("./../../../src/templates/news-category-archive.tsx" /* webpackChunkName: "component---src-templates-news-category-archive-tsx" */),
  "component---src-templates-news-single-tsx": () => import("./../../../src/templates/news-single.tsx" /* webpackChunkName: "component---src-templates-news-single-tsx" */),
  "component---src-templates-q-and-a-tsx": () => import("./../../../src/templates/q-and-a.tsx" /* webpackChunkName: "component---src-templates-q-and-a-tsx" */),
  "component---src-templates-student-works-archive-tsx": () => import("./../../../src/templates/student-works-archive.tsx" /* webpackChunkName: "component---src-templates-student-works-archive-tsx" */),
  "component---src-templates-student-works-single-tsx": () => import("./../../../src/templates/student-works-single.tsx" /* webpackChunkName: "component---src-templates-student-works-single-tsx" */),
  "component---src-templates-students-invited-tsx": () => import("./../../../src/templates/students-invited.tsx" /* webpackChunkName: "component---src-templates-students-invited-tsx" */),
  "component---src-templates-tutors-archive-tsx": () => import("./../../../src/templates/tutors-archive.tsx" /* webpackChunkName: "component---src-templates-tutors-archive-tsx" */),
  "component---src-templates-tutors-single-tsx": () => import("./../../../src/templates/tutors-single.tsx" /* webpackChunkName: "component---src-templates-tutors-single-tsx" */)
}

