import { useEffect, useState, type FC } from 'react';
import styled from 'styled-components';

import { Anchor } from 'src/components/common/atoms';
import bannerMobile01 from 'src/images/students_invited/mobile/01.gif';
import bannerMobile02 from 'src/images/students_invited/mobile/02.gif';
import bannerMobile03 from 'src/images/students_invited/mobile/03.gif';
import bannerMobile04 from 'src/images/students_invited/mobile/04.gif';
import bannerMobile05 from 'src/images/students_invited/mobile/05.gif';
import bannerPc01 from 'src/images/students_invited/pc/01.gif';
import bannerPc02 from 'src/images/students_invited/pc/02.gif';
import bannerPc03 from 'src/images/students_invited/pc/03.gif';
import bannerPc04 from 'src/images/students_invited/pc/04.gif';
import bannerPc05 from 'src/images/students_invited/pc/05.gif';
import { media, theme } from 'src/styles';
import { getLangPath, getRandomInt, useSlug } from 'src/utils';

type Props = {
  className?: string;
};

type BannerType = {
  pc: string;
  mobile: string;
}[];
const banners: BannerType = [
  {
    pc: bannerPc01,
    mobile: bannerMobile01,
  },
  {
    pc: bannerPc02,
    mobile: bannerMobile02,
  },
  {
    pc: bannerPc03,
    mobile: bannerMobile03,
  },
  {
    pc: bannerPc04,
    mobile: bannerMobile04,
  },
  {
    pc: bannerPc05,
    mobile: bannerMobile05,
  },
];

const getRandomIntExcludeCurrent = (
  length: number,
  excludeNubmer: number
): number => {
  const newNumber = getRandomInt(length);
  if (excludeNubmer !== newNumber) {
    return newNumber;
  }
  return getRandomIntExcludeCurrent(length, excludeNubmer);
};

const useCurrentNumber = () => {
  const [currentNumber, setCurrentNumber] = useState(
    getRandomInt(banners.length)
  );
  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentNumber((value) =>
        getRandomIntExcludeCurrent(banners.length, value)
      );
    }, 3800);
    return () => {
      clearInterval(timerId);
    };
  }, []);

  return currentNumber;
};
export const Banner: FC<Props> = ({ className }) => {
  const { lang } = useSlug();
  const to = getLangPath({ path: '/students-invited/', lang });
  const currentNumber = useCurrentNumber();
  return (
    <Anchor className={className ?? ''} to={to}>
      <StyledPicture>
        <source
          srcSet={banners[currentNumber].pc}
          media={`(min-width: ${theme.breakpoints.ipadVertical}px)`}
        />
        <img src={banners[currentNumber].mobile} alt={'students invited'} />
      </StyledPicture>
    </Anchor>
  );
};

const StyledPicture = styled.picture`
  ${media.lessThanIpadVertical`
    img {
      width: 75px;
    }
  `}
  ${media.ipadVerticalOrMore`
    img {
      width: 230px;
    }
  `}
`;

export default Banner;
